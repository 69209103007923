/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import emailjs from '@emailjs/browser';

const Form = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const publicKey = 'dtAmW1s2qu5V82Z0e';
    const serviceId = 'service_7z001vk';
    const templateId = 'template_kbj9wqp';

    useEffect(() => {
        emailjs.init(publicKey);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(serviceId, templateId, e.target)
            .then((result) => {
                // Debug: Log success
                console.log('Email successfully sent:', result.text);
                alert('Message sent successfully!');
            })
            .catch((error) => {
                // Debug: Log error
                console.error('Failed to send email:', error);
                alert('Failed to send the message, please try again.');
            });
    };

    return (
        <Box>
            <Box
                padding={{ xs: 3, sm: 6 }}
                width={'100%'}
                component={Card}
                borderRadius={2}
                boxShadow={4}
                marginBottom={4}
            >
                <form
                    id="contact-form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing={isMd ? 4 : 2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ height: 54 }}
                                label="First name"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                fullWidth
                                name="firstName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ height: 54 }}
                                label="Last name"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                fullWidth
                                name="lastName"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ height: 54 }}
                                label="Email"
                                type="email"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                fullWidth
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Message"
                                multiline
                                rows={6}
                                variant="outlined"
                                color="primary"
                                size="medium"
                                fullWidth
                                name="message"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                sx={{ height: 54 }}
                                variant="contained"
                                color="primary"
                                size="medium"
                                fullWidth
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography
                                    component="p"
                                    variant="body2"
                                    align="left"
                                >
                                    By clicking on &quot;submit&quot; you agree
                                    to our{' '}
                                    <Box
                                        component="a"
                                        href=""
                                        color={theme.palette.text.primary}
                                        fontWeight={'700'}
                                    >
                                        Privacy Policy
                                    </Box>
                                    ,{' '}
                                    <Box
                                        component="a"
                                        href=""
                                        color={theme.palette.text.primary}
                                        fontWeight={'700'}
                                    >
                                        Data Policy
                                    </Box>{' '}
                                    and{' '}
                                    <Box
                                        component="a"
                                        href=""
                                        color={theme.palette.text.primary}
                                        fontWeight={'700'}
                                    >
                                        Cookie Policy
                                    </Box>
                                    .
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box>
                <Typography color="text.secondary" align={'center'}>
                    We&apos;ll get back to you in 1-2 business days.
                </Typography>
            </Box>
        </Box>
    );
};

export default Form;
