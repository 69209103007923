/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Topbar, Sidebar, Footer } from './components';
import Container from 'common/Container';
import { pages } from '../navigation';

const Main = ({ children }) => {
    const theme = useTheme();
    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    return (
        <div>
            <Box>
                <AppBar
                    position={'fixed'}
                    sx={{
                        backgroundColor: theme.palette.alternate.main,
                    }}
                    elevation={1}
                >
                    <Container paddingY={{ xs: 1 / 2, sm: 2 }}>
                        <Topbar onSidebarOpen={handleSidebarOpen} />
                    </Container>
                </AppBar>
            </Box>
            <Sidebar
                onClose={handleSidebarClose}
                open={openSidebar}
                variant="temporary"
                pages={pages}
            />
            <main>
                <Box height={{ xs: 56, sm: 64 }} />
                {children}
                <Divider />
            </main>
            <Container paddingY={4}>
                <Footer />
            </Container>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node,
    themeToggler: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
};

export default Main;
