/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
	return (
		<Box>
			<Box marginBottom={4}>
				<Box
					component={Typography}
					fontWeight={700}
					variant={'h3'}
					align={'center'}
				> 
					Frequently Asked Questions
				</Box>
			</Box>
			<Box>
				{[
					{
						title: 'What services does your agency offer?',
						subtitle: 'We specialize in custom software development, UI/UX design, mobile app development, and system integration services.',
					},
					{
						title: 'How do I start a project with you?',
						subtitle: 'To start a project, simply contact us through our website form or call us directly. We’ll schedule a consultation to discuss your needs and outline the next steps.',
					},
					{
						title: 'What is your project development process?',
						subtitle:'Our project development process includes initial consultations, project scope definition, design and development phases, testing, and deployment. Each phase involves client feedback and iterations.',
					},
					{
						title: 'Can you work within my budget?',
						subtitle:  'We strive to work within the budgets of our clients by offering scalable solutions and prioritizing features to maximize value. Contact us to discuss your budget and requirements.',
					},
				].map((item, i) => (
					<Box
						component={Accordion}
						key={i}
						padding={1}
						marginBottom={i === item.length - 1 ? 0 : 2}
						borderRadius={2}
						sx={{
							'&::before': {
								display: 'none',
							},
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id={`panel1a-header--${i}`}
						>
							<Typography fontWeight={600}>{item.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography color="text.secondary">{item.subtitle}</Typography>
						</AccordionDetails>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default Faq;
