/* eslint-disable no-unused-vars */
import React from 'react';
// import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';

const Partners = () => {
    // const theme = useTheme();
    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'secondary'}
                    align={'center'}
                >
                    Our Valued Clients
                </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent={'center'}>
                {[
                    // '/assets/helixcp.png',
                    // '/assets/parkliio.png',
                    // '/assets/steamhouse.png',
                    // 'https://assets.maccarianagency.com/svg/logos/amazon-original.svg',
                    // 'https://assets.maccarianagency.com/svg/logos/fitbit-original.svg',
                    // 'https://assets.maccarianagency.com/svg/logos/netflix-original.svg',
                    // 'https://assets.maccarianagency.com/svg/logos/google-original.svg',
                    // 'https://assets.maccarianagency.com/svg/logos/paypal-original.svg',
                ].map((item, i) => (
                    <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
                        <Box
                            component="img"
                            height={'100%'}
                            width={'100%'}
                            src={item}
                            alt="..."
                            sx={{}}
                        />
                    </Box>
                ))}
                <Grid
                    container
                    gap={12}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid>
                        <img
                            src="/assets/parkliio.png"
                            alt="..."
                            width="150px"
                            height="35px"
                        />
                    </Grid>
                    <Grid>
                        <img
                            src="/assets/ratemylandlord.png"
                            alt="..."
                            width="300px"
                            height="35px"
                        />
                    </Grid>
                    <Grid>
                        <img
                            src="/assets/steamhouse.png"
                            alt="..."
                            width="100px"
                            height="100px"
                        />
                    </Grid>
                    <Grid>
                        <img
                            src="/assets/krisp.png"
                            alt="..."
                            width="150px"
                            height="50px"
                        />
                    </Grid>

                    {/* <Grid mt={2}>
                        <img
                            src="/assets/helixcp.png"
                            alt="..."
                            width="150px"
                            height="35px"
                        />
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
};

export default Partners;
