/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { colors } from '@mui/material';
import Container from 'common/Container';
import ExtensionIcon from '@mui/icons-material/Extension';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const Services = ({ themeMode = 'light' }) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
        defaultMatches: true,
    });
    const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true,
    });
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

    let slidesToShow = 1;
    if (isXs) {
        slidesToShow = 1;
    }
    if (isSm) {
        slidesToShow = 2;
    }
    if (isMd) {
        slidesToShow = 3;
    }
    if (isLg) {
        slidesToShow = 4;
    }

    const sliderOpts = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <Box>
            <Container paddingY={'0 !important'}>
                <Box marginBottom={4}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'medium',
                        }}
                        gutterBottom
                        color={'secondary'}
                    >
                        Services
                    </Typography>
                    <Box
                        component={Typography}
                        fontWeight={700}
                        variant={'h3'}
                        gutterBottom
                    >
                        High-impact design & development services
                    </Box>
                    <Typography
                        variant={'h6'}
                        component={'p'}
                        color={'textSecondary'}
                    >
                        We guide you through each step of the process ensuring a
                        smooth and efficient delivery of your project.
                    </Typography>
                </Box>
            </Container>
            <Box>
                <Slider {...sliderOpts}>
                    {/* // TODO: change icons for each card to reflect the service */}
                    {[
                        {
                            title: 'Custom Software Development',
                            subtitle:
                                'Tailor-made software solutions that perfectly align with your unique business processes, driving efficiency, innovation, and competitive edge."',
                            icon: <ExtensionIcon sx={{ fontSize: 48 }} />,
                            color: colors.purple,
                        },
                        {
                            title: 'Multi-Platform Development',
                            subtitle:
                                'Seamless, high-performance software solutions that work flawlessly across all devices and platforms, ensuring maximum reach and user satisfaction.',
                            icon: <DevicesOtherIcon sx={{ fontSize: 48 }} />,
                            color: colors.cyan,
                        },
                        {
                            title: 'Staff Augmentation',
                            subtitle:
                                'Enhance your team with our experienced professionals to meet your project needs, scale operations, and achieve your goals effectively.',
                            icon: <PeopleAltIcon sx={{ fontSize: 48 }} />, // Use an appropriate icon for Staff Augmentation
                            color: colors.blueGrey,
                        },
                        {
                            title: 'IT Strategy and Consulting',
                            subtitle:
                                'Expert guidance to align your technology investments with business goals, ensuring every digital initiative drives measurable value and competitive advantage.',
                            icon: (
                                <Diversity3OutlinedIcon sx={{ fontSize: 48 }} />
                            ),
                            color: colors.orange,
                        },
                        {
                            title: 'Product Design',
                            subtitle:
                                'We help you transform your idea into a live, intuitive and scalable digital product that your users will use and love.',
                            icon: <DesignServicesIcon sx={{ fontSize: 48 }} />,
                            color: colors.pink,
                        },
                        {
                            title: 'Artificial Intelligence and Machine Learning',
                            subtitle:
                                'Cutting-edge AI solutions that transform raw data into predictive insights, automated processes, and intelligent decision-making capabilities.',
                            icon: <MemoryOutlinedIcon sx={{ fontSize: 48 }} />,
                            color: colors.amber,
                        },
                        {
                            title: 'Funnel Optimization',
                            subtitle:
                                'We help you optimize your website or mobile app flow to increase conversion rates and retention to drive growth.',
                            icon: (
                                <TrendingUpOutlinedIcon sx={{ fontSize: 48 }} />
                            ),
                            color: colors.green,
                        },
                        {
                            title: 'Automation',
                            subtitle:
                                'We help your business gain leverage and efficiency through automation using simple, yet powerful NoCode tools.',
                            icon: (
                                <svg
                                    width={48}
                                    height={48}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            ),
                            color: colors.teal,
                        },
                        {
                            title: 'Data Analytics and Business Intelligence',
                            subtitle:
                                'Transform your data into a strategic asset with powerful analytics tools that deliver real-time, actionable insights for informed decision-making.',
                            icon: <AnalyticsIcon sx={{ fontSize: 48 }} />,
                            color: colors.red,
                        },
                        {
                            title: 'Cybersecurity',
                            subtitle:
                                'Comprehensive, adaptive security solutions that protect your digital assets, maintain customer trust, and ensure business continuity in an evolving threat landscape.',
                            icon: <LockOutlinedIcon sx={{ fontSize: 48 }} />,
                            color: colors.blue,
                        },
                        {
                            title: 'Cloud Services and Migration',
                            subtitle:
                                'Harness the full potential of cloud computing with our end-to-end services, from seamless migration to optimized management, scaling your business with confidence.',
                            icon: <CloudQueueIcon sx={{ fontSize: 48 }} />,
                            color: colors.yellow,
                        },
                    ].map((item, i) => (
                        <Box padding={{ xs: 1, md: 2, lg: 3 }} key={i}>
                            <Box
                                component={Card}
                                boxShadow={{ xs: 1, sm: 3 }}
                                borderRadius={5}
                                sx={{
                                    height: '400px', // Set a fixed height for the card
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    component={CardContent}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    padding={{ xs: 2, sm: 4, md: 6 }}
                                    sx={{
                                        '&:last-child': {
                                            paddingBottom: {
                                                xs: 2,
                                                sm: 4,
                                                md: 6,
                                            },
                                        },
                                        flexGrow: 1, // Ensure the content area grows to fill available space
                                    }}
                                >
                                    <Box
                                        component={Avatar}
                                        variant="rounded"
                                        width={84}
                                        height={84}
                                        marginBottom={2}
                                        bgcolor={
                                            themeMode === 'light'
                                                ? item.color[50]
                                                : item.color[200]
                                        }
                                        borderRadius={5}
                                    >
                                        <Box
                                            color={
                                                item.color[
                                                    themeMode === 'light'
                                                        ? 500
                                                        : 700
                                                ]
                                            }
                                        >
                                            {item.icon}
                                        </Box>
                                    </Box>
                                    <Box
                                        component={Typography}
                                        variant={'h6'}
                                        gutterBottom
                                        fontWeight={500}
                                        align={'center'}
                                    >
                                        {item.title}
                                    </Box>
                                    <Typography
                                        align={'center'}
                                        color="textSecondary"
                                    >
                                        {item.subtitle}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

Services.propTypes = {
    themeMode: PropTypes.string.isRequired,
};

export default Services;
