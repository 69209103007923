/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import WithLayout from 'WithLayout';

// Available layouts
import { Main as MainLayout } from './layouts';

// Landing pages
import { DesignAgency as DesignAgencyView } from './views/landingPages';

// Supporting pages
import { Contact as ContactView } from './views/supportingPages';

const Routes = () => {
    return (
        <ReactRoutes>
            <Route
                exact
                path="/"
                element={((matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={DesignAgencyView}
                        layout={MainLayout}
                    />
                ))()}
            />
            <Route
                exact
                path="/landing-design-agency"
                element={((matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={DesignAgencyView}
                        layout={MainLayout}
                    />
                ))()}
            />

            <Route
                exact
                path="/page-contact"
                element={((matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={ContactView}
                        layout={MainLayout}
                    />
                ))()}
            />
        </ReactRoutes>
    );
};

export default Routes;
