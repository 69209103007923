/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Grid';

const Topbar = () => {
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <Box display={'flex'} alignItems={'center'}>
                {/* <Box marginRight={{ xs: 1 }}>
                    <IconButton onClick={onSidebarOpen} aria-label="Menu">
                        <MenuIcon />
                    </IconButton>
                </Box> */}
                {/* <Grid container>
                    <img
                        src="/assets/burnscp.png"
                        alt="BurnsCP"
                        width="120px"
                        height="40px"
                    />
                </Grid> */}
                <Stack direction="row" spacing={-0.5}>
                    <Button
                        variant="text"
                        component={Link}
                        underline="none"
                        textDecoration="none"
                        to="/" // Path to your contact page
                        size="large"
                        sx={{
                            textTransform: 'none',
                            bgcolor: 'transparent',
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            '&:active': {
                                bgcolor: 'transparent',
                            },
                            '&:focus': {
                                bgcolor: 'transparent',
                            },
                            textDecoration: 'none',
                            color: 'inherit',
                        }}
                    >
                        {/* <Typography
                            display="inline-flex"
                            alignItems="center"
                            title="burnscp"
                            bgcolor="transparent"
                            color="black"
                            sx={{
                                fontSize: 'clamp(28px, 3.75vw, 32px)',
                                textAlign: 'center',
                                fontFamily: 'var(--font-sans)',
                                fontWeight: 900,
                                lineHeight: '100%',
                                letterSpacing:
                                    'clamp(-2.4px, calc(-1px - .1vw), -.96px)',
                                textWrap: 'balance',
                            }}
                        >
                            Burns Consulting
                        </Typography> */}
                        <img
                            src="/assets/burnscp.png"
                            alt="BurnsCP"
                            width="275px"
                            height="40px"
                        />
                    </Button>
                </Stack>
            </Box>
            <Box display="flex" alignItems={'center'}>
                <Box mx={2}>
                    {/* <IconButton
                        onClick={() => themeToggler()}
                        aria-label="Dark mode toggler"
                        color={themeMode === 'light' ? 'primary' : 'secondary'}
                    >
                        {themeMode === 'light' ? (
                            <svg
                                width={24}
                                height={24}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                />
                            </svg>
                        ) : (
                            <svg
                                width={24}
                                height={24}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                                />
                            </svg>
                        )}
                    </IconButton> */}
                </Box>
                <Box
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    alignItems={'center'}
                >
                    {/*<Box marginX={2}>
						<Link underline="none" component="a" href="/" color="textPrimary">
						Home
						</Link>
					</Box>*/}
                    {/*<Box marginX={2}>
						<Link
							underline="none"
							component="a"
							href="/docs-introduction"
							color="textPrimary"
						>
						Documentation
						</Link>
					</Box>*/}
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/page-contact"
                            size="large"
                        >
                            Contact Us
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func,
    themeToggler: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
};

export default Topbar;
