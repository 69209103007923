export const pages = [
    {
        title: 'Burns Consulting Partners',
        id: 'supporting-pages',
        pages: [
            {
                title: 'Contact',
                href: '/page-contact',
            },
        ],
    },
];
