export const light = {
    alternate: {
        main: '#f3f6ff',
        dark: '#eff2fc',
    },
    cardShadow: 'rgba(23, 70, 161, .11)',
    mode: 'light',
    primary: {
        main: '#6366f1',
        light: '#7a7df3',
        dark: '#4044ee',
        contrastText: '#fff',
    },
    secondary: {
        light: '#22c55e',
        main: '#22c55e',
        dark: '#22c55e',
        contrastText: '#ffffff',
    },
    text: {
        primary: '#0b0f19',
        secondary: '#565973',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
        paper: '#fff',
        default: '#fff',
        level2: '#f5f5f5',
        level1: '#fff',
    },
};

export const dark = {
    alternate: {
        main: 'rgba(255, 255, 255, .04)',
        dark: 'rgba(255, 255, 255, .04)',
    },
    cardShadow: 'rgba(0, 0, 0, .11)',
    common: {
        black: '#000',
        white: '#fff',
    },
    mode: 'dark',
    primary: {
        main: '#6366f1',
        light: '#3471f6',
        dark: '#3e41ee',
        contrastText: '#fff',
    },
    secondary: {
        light: '#22c55e',
        main: '#22c55e',
        dark: '#22c55e',
        contrastText: '#ffffff',
    },
    text: {
        primary: '#ffffff',
        secondary: '#eff2fc',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: {
        paper: '#222B45',
        default: '#222B45',
        level2: '#333',
        level1: '#2D3748',
    },
};
