import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import palette from './palette';

const GeistSans = {
    fontFamily: 'GeistSans, sans-serif',
    subset: 'latin',
};
const getTheme = (mode) =>
    responsiveFontSizes(
        createTheme({
            palette: palette(mode),
            layout: {
                contentWidth: 1236,
            },
            shadows: shadows(mode),
            typography: {
                fontFamily: GeistSans.fontFamily,
                button: {
                    textTransform: 'none',
                    fontWeight: 'medium',
                },
            },
            zIndex: {
                appBar: 1200,
                drawer: 1300,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        label: {},
                        containedSecondary:
                            mode === 'light' ? { color: 'white' } : {},
                    },
                },
            },
        })
    );

export default getTheme;
